import React from 'react'

import { useAuth } from '@azos/account'
import { useGuardian } from '@presentation/providers/GuardianProvider'
import { NextPage } from 'next'

import Home from './Home'
import { HomeAdapterProps } from './Home.props'

const HomeAdapter: NextPage<HomeAdapterProps> = ({ ...props }) => {
  const { user } = useAuth()
  const { isLoading: isLoadingGuardian, isGuardian } = useGuardian()

  return (
    <Home
      {...props}
      user={user}
      isLoadingGuardian={isLoadingGuardian}
      isGuardian={isGuardian}
    />
  )
}

export default HomeAdapter
