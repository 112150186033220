import React from 'react'

import analytics from '@azos/analytics'
import { NextPage } from 'next'

import { InsuredHome } from '../insuredHome'
import { NotInsuredHome } from '../notInsuredHome'
import { HomeProps } from './Home.props'

const Home: NextPage<HomeProps> = ({
  user,
  isLoadingGuardian,
  isGuardian,
  ...props
}) => {
  const isInsured = user?.isInsured

  React.useEffect(() => {
    if (user && !isLoadingGuardian) {
      analytics.dashboard.home.viewHomepage.execute({
        isInsured: user.isInsured,
        isGuardian: isGuardian,
        isNotInsuredNorGuardian: !user.isInsured && !isGuardian,
      })
    }
  }, [isGuardian, isLoadingGuardian, user])

  return isInsured ? <InsuredHome {...props} /> : <NotInsuredHome />
}

export default Home
